<template>
  <div class="noticelist_wrap">
    <ol class="notice_list">
      <li v-for="newsItem in newsList" :key="newsItem.Idx">
        <a :href="newsItem.LinkPath" class="notice_tit" target="_blank">
          <strong class="title">{{ newsItem.NewsTitle }}</strong>
          <span class="date">{{ changeDateFormat(newsItem.RegDT) }}</span>
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "NewsListWrap",
  props: {
    newsList: {
      type: Array,
    },
  },
  methods: {
    changeDateFormat(datetime) {
      return datetime.split("T")[0].replaceAll("-", ".")
    },
  },
}
</script>
