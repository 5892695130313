<template>
  <section class="sub_sec">
    <NewsListWrap :newsList="newsList" />
    <template v-if="more">
      <SubmitButton :btnObj="{ class: 'more', text: '더보기' }" @btnEvt="showMore" />
    </template>
  </section>
</template>

<script>
import SubmitButton from "@/components/SubmitButton.vue"
import NewsListWrap from "@/components/News/NewsListWrap.vue"

export default {
  name: "NewsList",
  components: {
    SubmitButton,
    NewsListWrap,
  },
  data() {
    return {
      more: false,
      newsList: [],
      currentPage: 0,
    }
  },
  created() {
    this.fetchNewsList()
  },
  methods: {
    showMore() {
      this.currentPage++
      this.fetchNewsList()
    },
    async fetchNewsList() {
      const httpData = {}
      httpData.Header = {
        CmdType: "010013",
        Browser: "CHROME",
        LocalIP: "192.0.0.0",
        RequestID: this.$guid(),
      }
      httpData.Body = {
        Length: 6,
        Offset: this.currentPage * 5,
      }

      try {
        const { data } = await this.$SRSServer(httpData)
        const sliceData = data.Body.splice(5, 1)

        if (sliceData.length === 0) this.more = false
        else this.more = true
        if (this.currentPage !== 0) {
          const concatArr = this.newsList.concat(data.Body)
          this.newsList = concatArr
        } else {
          this.newsList = data.Body
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
